import { useState } from 'react';
import { MinusIcon } from '@heroicons/react/solid';
import { PlusIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

const DisplayContainer = ({ title, className, children }) => {
	const [expand, setExpand] = useState(false);
	return (
		<div className={className ? className : 'mt-3'}>
			<button
				className={clsx(
					'flex items-center justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-700 hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75',
					expand
						? 'border border-gray-200 rounded-t-md'
						: 'bg-gray-100 rounded-md'
				)}
				onClick={() => setExpand(!expand)}
			>
				<span>{title}</span>
				<span className="ml-2">
					{expand ? (
						<MinusIcon className="w-4 h-4" aria-hidden="true" />
					) : (
						<PlusIcon className="w-4 h-4" aria-hidden="true" />
					)}
				</span>
			</button>
			{expand && (
				<div className="border border-gray-200 px-2 pt-2 rounded-b-md border-t-0">
					{children}
				</div>
			)}
		</div>
	);
};

export default DisplayContainer;
