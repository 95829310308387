import { useEffect, useState } from 'react';
import api from '../api';
import { useProject } from '@appmaker/core/hooks';
import appmaker from '@appmaker/core/index';
import JudgemeReviews from '../extensions/judgeme-reviews';
import { setExtensionList, useExtensionList as useExtensionListStore } from '../store';

const getLabelForType = (type) => {
    switch (type) {
        case 'public':
            return undefined
        case 'private':
            return 'Private app';
        case 'admin':
            return 'Admin only';
        default:
            return undefined
    }
}

const useExtensionList = ({ refetch = true } = {}) => {
    const [isLoading, setLoading] = useState(false);
    const { projectId } = useProject();
    const setValue = useExtensionListStore(data => data.setValue);
    useEffect(() => {
        const fetchList = async () => {
            setLoading(true);
            try {
                const response = await api.getList({ projectId });
                const { extensions = [] } = response;
                const data = extensions.map(extension => {
                    appmaker.extensions.register({
                        id: extension.id,
                        extensionId: extension.extensionId,
                        name: extension.name,
                        category: 'UI Kit',
                        href: '#',
                        price: extension.price === 0 ? 'Free' : `$ ${extension.price}`,
                        formSchema: extension.schema,
                        backendSchema: extension.backendSchema,
                        imageSrc: extension.image,
                        description: extension.description,
                        status: extension.status,
                        installedMeta: extension.installedMeta
                    });
                    return {
                        id: extension.id,
                        extensionId: extension.extensionId,
                        name: extension.name,
                        category: 'UI Kit',
                        href: '#',
                        price: extension.price === 0 ? 'Free' : `$ ${extension.price}`,
                        formSchema: extension.schema,
                        backendSchema: extension.backendSchema,
                        imageSrc: extension.image,
                        description: extension.description,
                        status: extension.status,
                        installedMeta: extension.installedMeta,
                        type: getLabelForType(extension.type),
                        packageName: extension?.packageName,
                        packageVersion: extension?.packageVersion
                    }

                })
                setExtensionList({ list: data, setValue: true, originalList: data });
            } catch (error) {

            }
            finally {
                setLoading(false);
            }
        }
        if (!setValue || refetch) {
            fetchList()
        }
    }, [])

    return {
        isLoading
    }
}

export { useExtensionList };