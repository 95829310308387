import appmaker from '@appmaker/core/index';
import Card from '../uikit/components/Card';
import {
	ProductVariationBlock,
	// ProductData,
	// ProductCounter,
	BlockList,
	BlockCard,
	InputItem,
	Button,
	LoadingSpinner,
	AppmakerJSON,
} from './BLockItems';
import * as DummyBlocks from './dummyBlocks';
import {
	DatabaseIcon,
	AcademicCapIcon,
	PhotographIcon,
	ChevronDoubleRightIcon,
	UserCircleIcon,
	QuestionMarkCircleIcon,
	PlayIcon,
	ServerIcon,
	DocumentTextIcon,
} from '@heroicons/react/solid';
import ProductGridItem from '../uikit/components/ProductGridItem';
import {
	ProductImage,
	ProductData,
	ProductCounter,
	ProductVariation,
	ExpandableTextBlock,
	Buttons,
	ExtraPaymentInfo,
	PinCheck,
} from '../uikit/components/product-detail';
import ActionBar from '../uikit/components/ActionBar';
import { OrderHeader } from '../uikit/components/order-detail';
import { BlogCard } from '../components';
import TableCell from '../uikit/components/TableCell';
import SezzleWidget from '../uikit/components/product-detail/SezzleWidget';
import WebView from './WebView';
import { isAdmin } from '@appmaker/core/store/user';
import { registerCustomBlocks } from './registerCustomBlocks';
import CountDownWidget from './CountDownWidget';
import Heading from './Heading';
import CoreMenu from './CoreMenu';
import DrawerLogin from './DrawerLogin';
import {
	ConditionBlock,
	ConditionBlockItem,
} from './condition/ConditionContainer';
import Video from './Video';
import BasicTabBlock, { BasicTabItem } from './BasicTabs';
import RichText from './RichText';
import DrawerHeader from './DrawerHeader';
function DummyBlock({ name }) {
	return (
		<div className="relative border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
			<div className="flex-shrink-0"></div>
			<div className="flex-1 min-w-0 text-center">
				<span className="absolute inset-0" aria-hidden="true" />
				<p className="text-sm font-medium text-gray-900">{name}</p>
			</div>
		</div>
	);
}
function HashimTest({ name }) {
	return (
		<div className="relative border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
			<div className="flex-shrink-0"></div>
			<div className="flex-1 min-w-0 text-center">
				<span className="absolute inset-0" aria-hidden="true" />
				<p className="text-sm font-medium text-gray-900">{name}</p>
			</div>
		</div>
	);
}
HashimTest.attributesSchema = {
	properties: {
		name: {
			type: 'string',
			default: '',
			uiType: 'DataSourceSelector',
		},
		layout: {
			type: 'string',
			default: '',
			uiType: 'LayoutSelector',
		},
		data: {
			type: 'string',
			default: '',
			uiType: 'DataSelector',
		},
	},
};
const oldBlocks = [
	'appmaker/banner',
	'appmaker/grid',
	'appmaker/grid-item',
	'appmaker/menu',
	'appmaker/menu-item',
	'appmaker/slider',
	'appmaker/slider-item',
	'appmaker/html',
	'appmaker/imagescroller',
	'appmaker/imagescroller-item',
	'appmaker/prebuildproductscroller',
	'appmaker/productscroller',
	'appmaker/productscroller-item',
];

const registerTemplateEditorBlocks = () => {
	appmaker.registerAppmakerBlockType('appmaker/banner', {
		title: 'Banner',
		icon: <DatabaseIcon />,
		Block: Card,
	});
	appmaker.registerAppmakerBlockType('appmaker/product-grid-item', {
		title: 'Product Grid item',
		icon: <DatabaseIcon />,
		Block: ProductGridItem,
	});
	appmaker.registerAppmakerBlockType('appmaker/product-data', {
		title: 'Product Data',
		icon: <AcademicCapIcon />,
		Block: ProductData,
	});
	appmaker.registerAppmakerBlockType('appmaker/form-input', {
		title: 'Input',
		icon: <AcademicCapIcon />,
		Block: InputItem,
	});
	appmaker.registerAppmakerBlockType('appmaker/product-image', {
		title: 'Product Image',
		icon: <PhotographIcon />,
		Block: ProductImage,
	});
	appmaker.registerAppmakerBlockType('appmaker/product-counter', {
		title: 'Product Counter',
		icon: <AcademicCapIcon />,
		Block: ProductCounter,
	});
	appmaker.registerAppmakerBlockType('appmaker/button', {
		title: 'Button',
		icon: <AcademicCapIcon />,
		Block: Button,
	});
	appmaker.registerAppmakerBlockType('appmaker/actionbar', {
		title: 'Action Bar',
		icon: <ChevronDoubleRightIcon />,
		Block: ActionBar,
	});
	appmaker.registerAppmakerBlockType('appmaker/expandable-text-block', {
		title: 'Expandable Text Block',
		icon: <AcademicCapIcon />,
		Block: ExpandableTextBlock,
	});
	appmaker.registerAppmakerBlockType('appmaker/product-variation', {
		title: 'Product Variation',
		icon: <AcademicCapIcon />,
		Block: ProductVariation,
	});
	appmaker.registerAppmakerBlockType('appmaker/buttons', {
		title: 'Detail Page Buttons',
		icon: <DatabaseIcon />,
		Block: Buttons,
	});
	appmaker.registerAppmakerBlockType('appmaker/json', {
		title: 'JSON',
		icon: <AcademicCapIcon />,
		Block: AppmakerJSON,
	});
	appmaker.registerAppmakerBlockType('appmaker/json', {
		title: 'JSON',
		icon: <AcademicCapIcon />,
		Block: AppmakerJSON,
	});
	appmaker.registerAppmakerBlockType('appmaker/payment-details', {
		title: 'Payment Details',
		icon: <AcademicCapIcon />,
		Block: ExtraPaymentInfo,
	});
	appmaker.registerAppmakerBlockType('appmaker/sezzle-in-widget', {
		title: 'Sezzle Widget',
		icon: <AcademicCapIcon />,
		Block: SezzleWidget,
	});
	appmaker.registerAppmakerBlockType('appmaker/order-detail-header', {
		title: 'Order detail header',
		icon: <AcademicCapIcon />,
		Block: OrderHeader,
	});
	appmaker.registerAppmakerBlockType('appmaker/card', {
		title: 'Blog Card',
		icon: <AcademicCapIcon />,
		Block: BlogCard,
	});
	appmaker.registerAppmakerBlockType('appmaker/table-cell', {
		title: 'Table Cell',
		icon: <AcademicCapIcon />,
		Block: TableCell,
	});
	appmaker.registerAppmakerBlockType('appmaker/pin-check', {
		title: 'Pin Check',
		icon: <AcademicCapIcon />,
		Block: PinCheck,
	});
	appmaker.registerAppmakerBlockType('appmaker/webview', {
		title: 'webview',
		icon: <AcademicCapIcon />,
		Block: WebView,
	});

	appmaker.registerAppmakerBlockType('appmaker/core-menu', {
		title: 'Core Menu',
		icon: <span className="text-xl font-black">M</span>,
		Block: CoreMenu,
	});
	appmaker.registerAppmakerBlockType('appmaker/drawer-login', {
		title: 'Drawer Login',
		icon: <UserCircleIcon />,
		Block: DrawerLogin,
	});
	appmaker.registerAppmakerBlockType('shopify/drawer-header', {
		title: 'Drawer Header Block',
		icon: <UserCircleIcon />,
		Block: DrawerHeader,
	});
	appmaker.registerAppmakerBlockType('appmaker/rich-text', {
		title: 'Rich Text',
		icon: <DocumentTextIcon />,
		Block: RichText,
	});

	const blocksT = Object.keys(DummyBlocks);
	blocksT.map((itemKey) => {
		const item = DummyBlocks[itemKey];
		if (item.blockData) {
			// console.log(item.blockData);
			appmaker.registerAppmakerBlockType(item.blockData.id, {
				title: item.blockData.title,
				icon: item.blockData.icon,
				Block: item,
			});
		}
	});
};
function registerPublicBlocks() {
	appmaker.registerAppmakerBlockType('appmaker/heading', {
		title: 'Heading',
		icon: <span className="text-xl font-black">H</span>,
		Block: Heading,
	});

	appmaker.registerAppmakerBlockType('appmaker/app-condition-block', {
		title: 'App Condition',
		icon: <QuestionMarkCircleIcon />,
		Block: ConditionBlock,
	});
	appmaker.registerAppmakerBlockType('appmaker/app-condition-block-item', {
		title: 'App Condition Item',
		icon: <QuestionMarkCircleIcon />,
		Block: ConditionBlockItem,
		parent: ['appmaker/app-condition-block'],
	});
	appmaker.registerAppmakerBlockType('appmaker/video', {
		title: 'Video',
		icon: <PlayIcon />,
		Block: Video,
	});
	appmaker.registerAppmakerBlockType('appmaker/basic-tabs', {
		title: 'Basic Tabs',
		icon: <ServerIcon />,
		Block: BasicTabBlock,
	});
	appmaker.registerAppmakerBlockType('appmaker/basic-tab-item', {
		title: 'Basic Tab Item',
		icon: <ServerIcon className="text-gray-400" />,
		Block: BasicTabItem,
		parent: ['appmaker/basic-tabs'],
	});
}
function registerBlockTypes() {
	oldBlocks.map((block) =>
		appmaker.registerAppmakerBlockType(block, {
			title: block,
			icon: <DatabaseIcon />,
			Block: () => <DummyBlock name={block} />,
		})
	);
	// For customers
	registerCustomBlocks();
	registerPublicBlocks();

	if (isAdmin()) {
		registerTemplateEditorBlocks();
	}

	// appmaker.registerAppmakerBlockType('appmaker/expandable-text-block', {
	// 	title: 'ExpandableTextBlock',
	// 	icon: <AcademicCapIcon />,
	// 	Block: ExpandableTextBlock,
	// });
	// appmaker.registerAppmakerBlockType('appmaker/buttons', {
	// 	title: 'AppmakerButtons',
	// 	icon: <AcademicCapIcon />,
	// 	Block: AppmakerButtons,
	// });
	// appmaker.registerAppmakerBlockType('appmaker/shopify-variation-listner', {
	// 	title: 'ShopifyVariationListner',
	// 	icon: <AcademicCapIcon />,
	// 	Block: ShopifyVariationListner,
	// });
	// ProductVariationBlock
	// Object.kr
}

export { registerBlockTypes };
