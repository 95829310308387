import appmaker from '@appmaker/core/index';
import { CubeTransparentIcon } from '@heroicons/react/solid';
import AdminToolsRoute from './routes';
import EjsPlayground from './routes/EjsPlayground';
// import ExtensionCreate from './routes/ExtensionCreate';
// import ExtensionUpdate from './routes/ExtensionUpdate';
import FormPreview from './routes/FormPreview';
import ShopifyCollectionExport from './routes/ShopifyCollectionExport';
import SvgImageEditor from './routes/SvgImages';
import ToggleAdmin from './routes/ToggleAdmin';
import TwigPlayground from './routes/TwigPlayground';
import CloudFareCache from './routes/CloudFareCache';
// import ExtensionUpdateFromParams from './routes/ExtensionUpdateParams';
import AppTagUpdate from './routes/TagUpdate';
import Staging from './routes/Staging';
import RegenerateIosCert from './routes/RegenerateIosCert';
import GraphqlExplorer from './routes/GraphqlExplorer';
import GraphqlExplorerOnline from './routes/GraphqlExplorerOnline';
import ShopifyGraphqlStoreFrontExplorer from './routes/ShopifyStorefrontGraphqlExplorer';
import ShopifyAdminGraphqlExplorer from './routes/ShopifyAdminGraphqlExplorer';
import LegacyAdmin from 'packages/AppmakerAdmin/routes/AdminTab';
import TemplateVersion from './routes/TemplateVersion';
import ExtensionProject from './routes/ExtensionProject';
import FormConverter from './routes/FormConverter';
import AppmakerGql from './routes/AppmakerGql';
import AppBuildConfig from './routes/AppBuildConfig';

const AdminTools = {
	id: '@appmaker/admin-tools',
	name: 'Admin tools',
	activate: () => {
		// appmaker.registerRoute({
		// 	routeName: '/admin-tools/extension-create',
		// 	component: ExtensionCreate,
		// });
		// appmaker.registerRoute({
		// 	routeName: '/admin-tools/extension-update/:extensionId',
		// 	component: ExtensionUpdateFromParams,
		// });
		// appmaker.registerRoute({
		// 	routeName: '/admin-tools/extension-update',
		// 	component: ExtensionUpdate,
		// });
		appmaker.registerRoute({
			routeName: '/admin-tools/form-preview',
			component: FormPreview,
		});
		appmaker.registerRoute({
			routeName: '/admin-tools/toggle-admin',
			component: ToggleAdmin,
		});
		appmaker.registerRoute({
			routeName: '/admin-tools/twig-playground',
			component: TwigPlayground,
		});
		appmaker.registerRoute({
			routeName: '/admin-tools/svg-to-image-editor',
			component: SvgImageEditor,
		});
		appmaker.registerRoute({
			routeName: '/admin-tools/ejs-playground',
			component: EjsPlayground,
		});
		appmaker.registerRoute({
			routeName: '/admin-tools/shopify-collection-export',
			component: ShopifyCollectionExport,
		});
		appmaker.registerRoute({
			routeName: '/admin-tools/cloudfare-cache-purge',
			component: CloudFareCache,
		});
		appmaker.registerRoute({
			routeName: '/admin-tools/app-tag',
			component: AppTagUpdate,
		});
		appmaker.registerRoute({
			routeName: '/admin-tools/staging',
			component: Staging,
		});
		appmaker.registerRoute({
			routeName: '/admin-tools/regenerate-ios-cert',
			component: RegenerateIosCert,
		});
		appmaker.registerRoute({
			routeName: '/admin-tools/graphql-explorer',
			component: GraphqlExplorer,
		});
		appmaker.registerRoute({
			routeName: '/admin-tools/graphql-explorer-online',
			component: GraphqlExplorerOnline,
		});
		appmaker.registerRoute({
			routeName: '/admin-tools/shopify-storefront-graphql',
			component: ShopifyGraphqlStoreFrontExplorer,
		});
		appmaker.registerRoute({
			routeName: '/admin-tools/shopify-admin-graphql',
			component: ShopifyAdminGraphqlExplorer,
		});
		appmaker.registerRoute({
			routeName: '/admin-tools/admin-legacy',
			component: LegacyAdmin,
		});
		appmaker.registerRoute({
			routeName: '/admin-tools/template-version',
			component: TemplateVersion,
		});
		appmaker.registerRoute({
			routeName: '/admin-tools/extension-project-list/:extensionId',
			component: ExtensionProject,
		})
		appmaker.registerRoute({
			routeName: '/admin-tools/form-converter',
			component: FormConverter,
		})
		appmaker.registerRoute({
			routeName: '/admin-tools/appmaker-gql',
			component: AppmakerGql
		})
		appmaker.registerRoute({
			routeName: '/admin-tools/app-build-config',
			component: AppBuildConfig
		})
		// appmaker.registerSideBarMenu({
		// 	name: 'Form Preview',
		// 	routeName: '/form-preview',
		// 	adminOnly: true,
		// 	icon: PencilIcon
		// },10)
		appmaker.registerRoute({
			routeName: '/admin-tools',
			component: AdminToolsRoute,
		});

		appmaker.registerSideBarMenu(
			{
				name: 'Admin Tools',
				routeName: '/admin-tools',
				adminOnly: true,
				icon: CubeTransparentIcon,
			},
			20
		);
	},
};

export default AdminTools;
