import './hooks/axios';
import axios from 'axios';
let ROOT_URL = 'https://app.appmaker.xyz';
let MAIN_URL = 'https://appmaker.xyz';
let GOOGLE_LOGIN_REDIRECT_URL = 'https://v3.appmaker.xyz';
let BASE_URL, V3_BASE_URL;

if (process.env.REACT_APP_RUN_ENV === 'staging') {
	ROOT_URL = 'https://staging-api.appmaker.xyz';
} else if (window.location.hostname === 'shopify-app-local.mobgap.com') {
	// ROOT_URL = 'https://app.appmaker.xyz';
	// ROOT_URL = 'http://localhost:3000';
	ROOT_URL = 'https://appmaker-api-local.mobgap.com';
	console.log('Tunnel env !', ROOT_URL, process.env.NODE_ENV);
} else if (process.env.NODE_ENV === 'development') {
	ROOT_URL = 'https://app.appmaker.xyz';
	// ROOT_URL = 'https://appmaker-api-local.mobgap.com';
	// ROOT_URL = 'http://localhost:3000';
	GOOGLE_LOGIN_REDIRECT_URL = 'http://localhost:3001';
	console.log('Dev env !', ROOT_URL);
} else {
	// production code - do not edit
	ROOT_URL = 'https://app.appmaker.xyz';
}

V3_BASE_URL = `${ROOT_URL}/v3`;
BASE_URL = `${ROOT_URL}/v2`;
const DASHBOARD_GRAPHQL_URL = `${ROOT_URL}/dashboard/graphql/v1`;
let APPMAKER_GQL_URL = `${ROOT_URL}/v3/appmaker-gql`
export {
	BASE_URL,
	V3_BASE_URL,
	ROOT_URL,
	MAIN_URL,
	DASHBOARD_GRAPHQL_URL,
	GOOGLE_LOGIN_REDIRECT_URL,
	APPMAKER_GQL_URL
};

// console.log(BASE_URL);
const apiFunction = {
	user: async (options) => {
		options = options || {};
		const { withCredentials = true } = options;
		try {
			// console.log('Login req', `${BASE_URL}/user`);
			const response = await axios.get(`${BASE_URL}/user`, {
				credentials: 'include',
				withCredentials: withCredentials,
			});
			// console.log('Login res test', response.data);
			return response.data;
		} catch (error) {
			console.log('Login err', error);
			throw error;
		}
	},
	getProject: async (projectId) => {
		try {
			const response = await axios.get(`${BASE_URL}/projects/${projectId}`, {
				credentials: 'include',
				withCredentials: true,
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	getProjectGraphQl: async (projectId) => {
		const query = `
        query{
			project {
			  url
			  name
			  subscription {
				status
			  }
			  oauthProviders {
				name
			  }
			  apps{
				id,
				version,
				token,
				platform,
				buildInProgress,
			  }
			  templateTag
			  meta {
				hideLegacyDownload
			  }
			  blocks {
				id
				name
				description
				title
				imageUrl
				attributesSchema
				themeId
				extensionId
				config
			  }
			}
		  }
        `;
		const response = await axios.post(
			`${DASHBOARD_GRAPHQL_URL}/${projectId}/`,
			JSON.stringify({
				query,
			}),
			{
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				withCredentials: true,
			}
		);
		return response.data?.data?.project;
	},
	allProjects: async () => {
		try {
			const response = await axios.get(
				`${BASE_URL}/projects/list-project?isActiveProjects=true`,
				{
					credentials: 'include',
					withCredentials: true,
				}
			);
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	getCustomizeForm: async (projectId, type = 'create') => {
		try {
			const url = `${BASE_URL}/manage-project/get/${projectId}?mode=${type}&version=3`;
			// const url = `${TEST_URL}`;
			const response = await axios.get(url, {
				credentials: 'include',
				withCredentials: true,
			});

			return Promise.resolve(response.data);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	saveAppFormData: async (projectId, data, skipBuild = true) => {
		try {
			const url = `${BASE_URL}/manage-project/update/${projectId}?skipBuild=${skipBuild}`;
			// const url = `http://localhost:3000/v2/manage-project/update/${projectId}?skipBuild=${skipBuild}`
			const response = await axios.post(
				url,
				{ data },
				{
					credentials: 'include',
					withCredentials: true,
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
				}
			);
			return Promise.resolve(response.data);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	createProject: async (data) => {
		try {
			const url = `${BASE_URL}/manage-project/create`;
			// const url = `http://localhost:3000/v2/manage-project/create`;
			const response = await axios.post(url, data, {
				credentials: 'include',
				withCredentials: true,
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	uploadFile: async (projectId, data, tagName, extraParams) => {
		try {
			let url = `${BASE_URL}/file-upload/${projectId}?tagName=${tagName}`;
			if (extraParams) {
				url += `&${extraParams}`;
			}
			const response = await axios.post(url, data, {
				credentials: 'include',
				withCredentials: true,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	getTranslationTab: async (projectId, languageCode) => {
		try {
			//  const url = `http://localhost:3000/v2/projects/${projectId}/languages/translation-schema`;
			const url = `${BASE_URL}/projects/${projectId}/languages/translation-schema`;
			//  const url = `https://920dbeae33.to.intercept.rest/`;
			const response = await axios.get(url, {
				credentials: 'include',
				withCredentials: true,
			});

			return Promise.resolve(response.data);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	getDefaultLanguageValues: async (projectId, languageCode) => {
		try {
			// const url = `http://localhost:3000/v2/projects/${projectId}/languages/default-values/${languageCode}`;
			const url = `${BASE_URL}/projects/${projectId}/languages/default-values/${languageCode}`;
			//  const url = `https://920dbeae33.to.intercept.rest/`;
			const response = await axios.get(url, {
				credentials: 'include',
				withCredentials: true,
			});

			return Promise.resolve(response.data);
		} catch (error) {
			return Promise.reject(error);
		}
	},

	notifyPluginConfigChanged: async (projectId) => {
		try {
			// const url = `http://localhost:3000/v2/projects/${projectId}/languages/default-values/${languageCode}`;
			const url = `${BASE_URL}/projects/${projectId}/plugin-configured`;
			//  const url = `https://920dbeae33.to.intercept.rest/`;
			const response = await axios({
				method: 'POST',
				url,
				withCredentials: true,
			}); // Log plugin configured to server

			return Promise.resolve(response.data);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	// app buid status
	appBuildStatus: async (projectId) => {
		// const url = `${ROUTES.URL}/api/v3/projects/status?project=${projectId}`;
		const url = `${BASE_URL}/projects/${projectId}/app-build-status`;
		try {
			const response = await axios.get(url, {
				method: 'GET',
				credentials: 'include',
				withCredentials: true,
			});
			return Promise.resolve(response.data);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	getLanguageValues: async (projectId) => {
		const url = `${BASE_URL}/projects/${projectId}/languages/get-values`;
		try {
			const response = await axios.get(url, {
				method: 'GET',
				credentials: 'include',
				withCredentials: true,
			});
			return Promise.resolve(response.data);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	saveLanguageValues: async (projectId, data) => {
		const url = `${BASE_URL}/projects/${projectId}/languages/update-language`;
		try {
			const response = await axios.post(url, data, {
				credentials: 'include',
				withCredentials: true,
			});
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	},
	buildProject: async (projectId) => {
		const url = `${BASE_URL}/projects/${projectId}/build`;
		try {
			const response = await axios.post(
				url,
				{},
				{
					credentials: 'include',
					withCredentials: true,
				}
			);
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	},
	getPublicMeta: async (projectId) => {
		const url = `${BASE_URL}/projects/${projectId}/public/public_meta`;
		const response = await axios.get(url, {
			method: 'GET',
			credentials: 'include',
			withCredentials: true,
		});
		return response.data;
	},
	setPublicMeata: async (projectId, body) => {
		const url = `${BASE_URL}/projects/${projectId}/public/public_meta`;
		try {
			const response = await axios.post(url, body, {
				credentials: 'include',
				withCredentials: true,
			});
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	},
	setMeta: async (projectId, metaName, body) => {
		const url = `${BASE_URL}/projects/${projectId}/meta/${metaName}`;
		try {
			const response = await axios.post(url, body, {
				credentials: 'include',
				withCredentials: true,
			});
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	},
	getMeta: async (projectId, metaName) => {
		const url = `${BASE_URL}/projects/${projectId}/meta/${metaName}`;
		try {
			const response = await axios.get(url, {
				credentials: 'include',
				withCredentials: true,
			});
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	},
	deleteFile: async (projectId, tagName, extraParams) => {
		try {
			let url = `${BASE_URL}/file-upload/${projectId}?tagName=${tagName}`;
			if (extraParams) {
				url += `&${extraParams}`;
			}
			const response = await axios.delete(url, {
				credentials: 'include',
				withCredentials: true,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	downloadApp: ({ projectId, preview, token, platform, bundle }) => {
		let url = `${BASE_URL}/download-app/${projectId}?preview=${preview}&token=${token}&platform=${platform}`;
		if (bundle) {
			url += `&bundle=${bundle}`;
		}
		return url;
	},
};
export default apiFunction;
