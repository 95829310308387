import { useThemeList } from '../store';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import { CubeIcon, SearchIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { isAdmin as checkIsAdmin } from '@appmaker/core/store/user';

const List = () => {
	const themeList = useThemeList((data) => data.list);
	const activeTheme = useThemeList((data) => data.activeTheme);
	const getRouterPath = useRouterPath();
	const isAdmin = checkIsAdmin();
	// const installed = true;
	const checkInstalled = (status) => {
		return status === 'active';
	};
	const checkActive = (themeId) => {
		return activeTheme && themeId === activeTheme.themeId;
	};
	const installedThemes = themeList
		? themeList.filter((item) => item.installedMeta)
		: [];
	return (
		<div className="bg-gray-50 h-screen overflow-y-auto">
			<div className="max-w-2xl  mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
				<div className="flex items-center justify-between space-x-4 ">
					<h2 className="text-lg font-medium text-gray-900">
						Installed Themes
					</h2>
					<div className="relative border border-indigo-500 rounded-md text-gray-400 focus-within:text-gray-600">
						<div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
							<SearchIcon className="h-5 w-5" aria-hidden="true" />
						</div>
						<input
							id="search"
							className="block w-full bg-white py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white sm:text-sm"
							placeholder="Search"
							type="search"
							name="search"
							onChange={(event) => {
								// searchExtension(event.target.value)
							}}
						/>
					</div>
					{/* <a
						href="#"
						className="whitespace-nowrap text-sm font-medium text-indigo-600 hover:text-indigo-500"
					>
						View all<span aria-hidden="true"> &rarr;</span>
					</a> */}
				</div>
				<div className="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-y-6 lg:grid-cols-3">
					{installedThemes.length === 0 ? (
						<div className="p-4 group rounded-xl bg-white flex flex-col justify-center items-center border border-dashed border-gray-200">
							<CubeIcon className="h-16 w-16 p-4 bg-gray-200 rounded-full" />
							<h3 className="text-md font-medium text-gray-400 mt-3">
								No active themes
							</h3>
						</div>
					) : null}
					{installedThemes.map((product) => (
						<Link
							to={getRouterPath('/themes/' + product.id)}
							key={product.id}
							className={clsx(
								'p-4 group rounded-xl border bg-white hover:shadow-sm relative',
								checkInstalled(product.status)
									? 'border-green-400'
									: 'border-gray-300 hover:border-blue-600'
							)}
						>
							{checkActive(product.themesId) && (
								<span className="bg-green-100 text-green-700 px-1 text-sm absolute right-4 top-0 rounded-b">
									Active
								</span>
							)}

							<div className="relative group flex items-center space-x-3">
								<div className="aspect-square rounded-lg overflow-hidden bg-gray-100 h-16 w-16">
									<img
										src={product.image}
										alt={product.name}
										className="object-contain object-center h-16 w-16"
									/>
								</div>
								<div>
									<h3 className="text-md font-medium group-hover:text-blue-600 capitalize">
										{product.name}
									</h3>
									<h4 className="text-md font-bold text-gray-700">
										{product?.price === 0 ? 'Free' : `$ ${product?.price}`}
									</h4>
									{isAdmin && (
												<>
													{product?.packageName &&
														(	
														<p className="text-xs text-gray-700">
														{product?.packageName}
														</p>
														)
													}
													{product?.packageVersion && <p className="text-xs text-gray-700">
														Version: {product?.packageVersion}
													</p>}
												</>
									)}

								</div>
							</div>
							<p className="mt-2 text-gray-500 text-sm line-clamp-3">
								{/* {product.imageAlt} */}
							</p>
							{/* <button
								type="button"
								className="bg-blue-600 mt-4 border border-transparent rounded-md py-1 px-3 flex items-center justify-center text-base font-normal text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
							>
								Activate
							</button> */}
						</Link>
					))}
				</div>
			</div>
			{isAdmin && (
				<div className="max-w-2xl  mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
					<div className="flex items-center justify-between space-x-4 ">
						<h2 className="text-lg font-medium text-gray-900">
							Available Themes
						</h2>
						{/* <a
                      href="#"
                      className="whitespace-nowrap text-sm font-medium text-indigo-600 hover:text-indigo-500"
                  >
                      View all<span aria-hidden="true"> &rarr;</span>
                  </a> */}
					</div>
					<div className="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-y-6 lg:grid-cols-3">
						{themeList.map((product) => (
							<Link
								to={getRouterPath('/themes/' + product.id)}
								key={product.id}
								className={clsx(
									'p-4 group rounded-xl border bg-white hover:shadow-sm relative',
									checkInstalled(product.status)
										? 'border-green-400'
										: 'border-gray-300 hover:border-blue-600'
								)}
							>
								{checkInstalled(product.status) && (
									<span className="bg-green-100 text-green-700 px-1 text-sm absolute right-4 top-0 rounded-b">
										Installed
									</span>
								)}
								<div className="relative group flex items-center space-x-3">
									<div className="aspect-square rounded-lg overflow-hidden bg-gray-100 h-16 w-16">
										<img
											src={product.image}
											alt={product.name}
											className="object-contain object-center h-16 w-16"
										/>
									</div>
									<div>
										<h3 className="text-md font-medium group-hover:text-blue-600 capitalize">
											{product.name}
										</h3>
										<h4 className="text-md font-bold text-gray-700">
											{product?.price === 0 ? 'Free' : `$ ${product?.price}`}
											{product.type && (
												<div className="mr-2">
													<span className="bg-red-100 text-red-700 px-1 text-sm  right-4  rounded-b">
														{product.type}
													</span>
												</div>
											)}
										</h4>
									</div>
								</div>
								<p className="mt-2 text-gray-500 text-sm line-clamp-3">
									{/* {product.imageAlt} */}
								</p>
							</Link>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default List;
