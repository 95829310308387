import appmaker from '@appmaker/core/index';
import ExtensionHome from './routes/ExtensionHome';
import ExtensionDetail from './routes/ExtensionDetail';
import { CubeIcon } from '@heroicons/react/solid';
import { addNewPlugin } from '@appmaker/core/store/project';
const Plugin = {
	id: '@appmaker/extensions',
	name: 'Extensions',
	activate: () => {
		appmaker.registerRoute({
			routeName: '/extensions/:extensionId',
			component: ExtensionDetail,
		});
		appmaker.registerRoute({
			routeName: '/extensions',
			component: ExtensionHome,
		});
		/**
		 * Menu Items
		 */
		appmaker.registerSideBarMenu({
			name: 'Extensions',
			routeName: '/extensions',
			icon: CubeIcon,
		});
		// hooks called when any of the extension activate
		appmaker.addAction('extension-activate', 'appmaker-extension', (newPlugin) => {
			// update the newly added extension to project store. REASON: Project api is only called initially
			addNewPlugin(newPlugin);
			appmaker.activatePlugins([newPlugin]);
		})
		// hooks called when any of the extension deactivate
		appmaker.addAction('extension-deactivate', 'appmaker-extension', (args) => {
			// console.log(args, 'yy', 'in-deactivate')
		})
	},
};

export default Plugin;
