import { getProjectData } from "@appmaker/core/store/project"
import { Redirect } from '@shopify/app-bridge/actions';



const RedirectConsole = () => {
    const data = getProjectData();
    const { projectData = {}, projectId } = data;
    const { migration = {} } = projectData
    const { isExtensionInstallMigrated = false, isThemeInstallMigrated = false } = migration;

    const redirect = (url) => {
        if (!window.shopifyApp) {
            window.location.href = url;
        } else if (window.shopifyApp) {
            const redirect = Redirect.create(window.shopifyApp);
            redirect.dispatch(Redirect.Action.REMOTE, url);
        }
    }

    if (isExtensionInstallMigrated && isThemeInstallMigrated) {
        redirect('https://console.appmaker.xyz/apps/' + projectId)
    }
    return null;
}

export default RedirectConsole