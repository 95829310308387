import api from '../api';
import { useEffect, useState } from 'react';
import { useProject } from '@appmaker/core/hooks';
import { setThemeList } from '../store';
import Loader from '@appmaker/components/Loading';
import List from '../components/List';
import RedirectConsole from 'packages/extensions/routes/RedirectToConsole';
const Home = () => {
    const [isLoading, setLoading] = useState(false);
    const { projectId } = useProject();
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const response = await api.getList({ projectId });
                const {
                    themes = [],
                    active={}
                } = response;
                setThemeList({
                    list: themes,
                    setValue: true,
                    originalList: themes,
                    activeTheme: active
                })
            } catch (error) {

            }
            finally {
                setLoading(false)
            }
        })()
    }, [])
    if (isLoading) return <Loader />

    return <div>
        <RedirectConsole id='theme'/>
        <List />
    </div>
}


export default Home;