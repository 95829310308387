import {
	APPMAKER_GQL_URL,
} from '@appmaker/core/api/core';
import axios from 'axios';

const getAppBuild = (projectId, id) => {
	if (!projectId || !id) return null;
	let query = `
    query ($where: AppBuildWhereUniqueInput!) {
        appBuild(where: $where) {
          buildStatus
          config
          legacyId
          appId {
            platform
            id
            remoteAppId
          }
          project {
            remoteProjectId
          }
          id
          name
          platform
          version
          buildType
          triggerType
          triggerBy {
            name
          }
          version
          createdAt
          updatedAt
          downloadUrl
          qaStatus
          releaseStatus
          packages
          templateVersion {
            legacyId
            name
            tag
            version
          }
        }
      }
      
    `;
	return axios.post(
		APPMAKER_GQL_URL,
		JSON.stringify({
			query,
			variables: {
				where: {
					id: id,
				},
			},
		}),
		{
			headers: {
				'Content-Type': 'application/json',
				'x-appmaker-project-id': projectId,
			},
			credentials: 'include',
			withCredentials: true,
		}
	);
};

export { getAppBuild };
