import { DownloadIcon } from '@heroicons/react/solid';
import React, { useState, useRef, Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import DownloadAppCards from './DownloadAppCards';
import { useProject } from '@appmaker/core/store/project';
import coreApi from '@appmaker/core/api/core';
import { useQueryParam, StringParam } from 'use-query-params';
import NewDownloadApp from './NewAppDownload';
import useProjectGraphQl from '@appmaker/core/store/project-graphql';

function MyModal({ isOpen, setIsOpen }) {
	const [showAppDownload] = useQueryParam('showAppDownload', StringParam);

	let buildCheckIntervalId = false;
	const { projectData } = useProject();
	const [appBuildStatus, setAppBuildStatus] = useState();

	// console.log(projectData);

	function closeModal() {
		setIsOpen(false);
		if (buildCheckIntervalId !== false) {
			clearInterval(buildCheckIntervalId);
		}
	}

	async function getBuildStatus() {
		const status = await coreApi.appBuildStatus(projectData.id);
		setAppBuildStatus(status.apps);
		if (status.buildInProgress < 1) {
			clearInterval(buildCheckIntervalId);
		}
	}

	function startBuildStatusCheck() {
		getBuildStatus();
		buildCheckIntervalId = setInterval(() => {
			getBuildStatus();
		}, 5000);

		// clear interval on re-render to avoid memory leaks
		return () => clearInterval(buildCheckIntervalId);
	}

	useEffect(() => {
		if (isOpen) return startBuildStatusCheck();
	}, [isOpen]);

	useEffect(() => {
		if (showAppDownload) {
			setIsOpen(true);
		} else {
			setIsOpen(false);
		}
	}, [showAppDownload]);

	return (
		<>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					onClose={closeModal}
				>
					<Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

					<div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="inline-block h-screen align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-block w-full max-w-4xl my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
								<Dialog.Title
									as="h3"
									className="text-xl font-bold leading-6 text-gray-800 px-6 pt-6"
								>
									Download Apps
								</Dialog.Title>
								<div className="px-6 pb-6">
									<DownloadAppCards
										apps={projectData.apps}
										appBuildStatus={appBuildStatus}
									/>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
}

export default function TemplateBuilder() {
	let [isOpen, setIsOpen] = useState(false);
	const { data = {} } = useProjectGraphQl();
	const { meta: { hideLegacyDownload = false } = {} } = data;

	return (
		<div>
			{!hideLegacyDownload && <MyModal isOpen={isOpen} setIsOpen={setIsOpen} />}
			{hideLegacyDownload && (
				<NewDownloadApp isOpen={isOpen} setIsOpen={setIsOpen} />
			)}
			<button
				onClick={() => setIsOpen(!isOpen)}
				type="button"
				className="relative inline-flex items-center px-4 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-blue-500"
			>
				<DownloadIcon className={'-ml-1 mr-2 h-5 w-5'} aria-hidden="true" />
				<span>Download App</span>
			</button>
		</div>
	);
}
