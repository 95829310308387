import { Link } from 'react-router-dom';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import appmaker from '@appmaker/core/index';
import clsx from 'clsx';
import { useExtensionList, searchExtension, showUnSavedChanges } from '../store';
import { CubeIcon, SearchIcon } from '@heroicons/react/outline';
import { isAdmin as checkIsAdmin } from '@appmaker/core/store/user';
import AppmakerRadioGroup from '@appmaker/core/components/AppmakerForm/fields/RadioGroup';
import { useEffect, useState } from 'react';
import useFeatureList from '../hooks/enableFeature';

export default function ExtensionList({ title }) {
	const isAdmin = checkIsAdmin();
	const [installedExtension, setInstalledExtension] = useState([]);
	const features = useFeatureList();
	const extensionList = useExtensionList((data) => data.list);
	// const extensions = Object.values(appmaker.extensions.listAll());
	const extensions = extensionList;
	const getRouterPath = useRouterPath();
	// const installed = true;
	const checkInstalled = (status) => {
		return status === 'installed';
	};
	const checkActive = (status) => {
		return status === 'active';
	};
	const filterExtension = (filterType) => {
		switch (filterType) {
			case 'installed': {
				return setInstalledExtension(
					extensionList?.filter((item) => item?.status === 'installed') ?? []
				);
			}
			case 'active': {
				return setInstalledExtension(
					extensionList?.filter((item) => item?.status === 'active') ?? []
				);
			}
			case 'all':
			default: {
				setInstalledExtension(
					extensionList?.filter((item) => item.installedMeta) ?? []
				);
			}
		}
	};
	useEffect(() => {
		setInstalledExtension(
			extensionList ? extensionList.filter((item) => item.installedMeta) : []
		);
		// Fix: when unsaved changes is turned on and switching to another extension
		showUnSavedChanges(false);
	}, [extensionList]);

	return (
		<div className="bg-gray-50 h-screen overflow-y-auto">
			<div className="max-w-2xl  mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
				<div className="flex items-center justify-between space-x-4 ">
					<h2 className="text-lg font-medium text-gray-900">
						Installed Extensions
					</h2>
					<div className="flex items-center space-x-4">
						<AppmakerRadioGroup
							properties={{
								type: 'string',
								// title: 'Filter',
								className: 'mt-0',
								radioStyles: 'px-3 py-2',
								row: true,
								options: [
									{
										label: 'All',
										value: 'all',
									},
									{
										label: 'Installed',
										value: 'installed',
									},
									{
										label: 'Active',
										value: 'active',
									},
								],
							}}
							defaultValue={'all'}
							onChange={(value) => {
								filterExtension(value);
							}}
						/>
						<div className="relative border border-indigo-500 rounded-md text-gray-400 focus-within:text-gray-600">
							<div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
								<SearchIcon className="h-5 w-5" aria-hidden="true" />
							</div>
							<input
								id="search"
								className="block w-full bg-white py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white sm:text-sm"
								placeholder="Search"
								type="search"
								name="search"
								onChange={(event) => {
									searchExtension(event.target.value);
								}}
							/>
						</div>
					</div>
					{/* <a
						href="#"
						className="whitespace-nowrap text-sm font-medium text-indigo-600 hover:text-indigo-500"
					>
						View all<span aria-hidden="true"> &rarr;</span>
					</a> */}
				</div>
				<div className="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-y-6 lg:grid-cols-3">
					{installedExtension.length === 0 ? (
						<div className="p-4 group rounded-xl bg-white flex flex-col justify-center items-center border border-dashed border-gray-200">
							<CubeIcon className="h-16 w-16 p-4 bg-gray-200 rounded-full" />
							<h3 className="text-md font-medium text-gray-400 mt-3">
								No active extension
							</h3>
						</div>
					) : null}
					{installedExtension.map((product) => (
						<Link
							to={getRouterPath('/extensions/' + product.id)}
							key={product.id}
							className={clsx(
								'p-4 group rounded-xl border bg-white hover:shadow-sm relative',
								checkActive(product.status)
									? 'border-green-400'
									: 'border-gray-300 hover:border-blue-600'
							)}
						>
							{/* {checkActive(product.status) && (
								<span className="bg-green-100 text-green-700 px-1 text-sm absolute right-4 top-0 rounded-b">
									Activated
								</span>
							)} */}
							{product?.status === 'active' && (
								<span className="bg-green-100 text-green-700 px-1 text-sm absolute right-4 top-0 rounded-b">
									Activated
								</span>
							)}
							{product?.status === 'installed' && (
								<span className="bg-green-100 text-green-700 px-1 text-sm absolute right-4 top-0 rounded-b">
									Installed
								</span>
							)}
							<div className="relative group flex items-center space-x-3">
								<div className="aspect-square rounded-lg overflow-hidden bg-gray-100 h-16 w-16">
									<img
										src={product.imageSrc}
										alt={product.imageAlt}
										className="object-contain object-center h-16 w-16"
									/>
								</div>
								<div>
									<h3 className="text-md font-medium group-hover:text-blue-600 capitalize">
										{product.name}
									</h3>
									<h4 className="text-md font-bold text-gray-700">
										{product.price}
									</h4>
									{isAdmin && (
										<>
											{product?.packageName &&
											 	(	
											 	<p className="text-xs text-gray-700">
												{product?.packageName}
												</p>
												)
											}
											{product?.packageVersion && <p className="text-xs text-gray-700">
												Version: {product?.packageVersion}
											</p>}
										</>
									)}
								</div>
							</div>
						</Link>
					))}
				</div>
			</div>
			{(true || isAdmin || features?.enableExtensionList) && extensions && extensions.length > 0 && (
				<div className="max-w-2xl  mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
					<div className="flex items-center justify-between space-x-4 ">
						<h2 className="text-lg font-medium text-gray-900">{title}</h2>
						{/* <a
						href="#"
						className="whitespace-nowrap text-sm font-medium text-indigo-600 hover:text-indigo-500"
					>
						View all<span aria-hidden="true"> &rarr;</span>
					</a> */}
					</div>
					<div className="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-y-6 lg:grid-cols-3">
						{extensions.map((product) => (
							<Link
								to={getRouterPath('/extensions/' + product.id)}
								key={product.id}
								className={clsx(
									'p-4 group rounded-xl border bg-white hover:shadow-sm relative',
									checkActive(product.status)
										? 'border-green-400'
										: 'border-gray-300 hover:border-blue-600'
								)}
							>
								{/* {checkInstalled(product.status) && (
									<span className="bg-green-100 text-green-700 px-1 text-sm absolute right-4 top-0 rounded-b">
										Activated
									</span>
								)} */}
								{product?.status === 'active' && (
									<span className="bg-green-100 text-green-700 px-1 text-sm absolute right-4 top-0 rounded-b">
										Activated
									</span>
								)}
								{product?.status === 'installed' && (
									<span className="bg-green-100 text-green-700 px-1 text-sm absolute right-4 top-0 rounded-b">
										Installed
									</span>
								)}
								<div className="relative group flex items-center space-x-3">
									<div className="aspect-square rounded-lg overflow-hidden bg-gray-100 h-16 w-16">
										<img
											src={product.imageSrc}
											alt={product.imageAlt}
											className="object-contain object-center h-16 w-16"
										/>
									</div>
									<div>
										<h3 className="text-md font-medium group-hover:text-blue-600 capitalize">
											{product.name}
										</h3>
										<h4 className="text-md font-bold text-gray-700">
											{product.price}
											{product.type && (
												<div className="mr-2">
													<span className="bg-red-100 text-red-700 px-1 text-xs right-4 rounded">
														{product.type}
													</span>
												</div>
											)}
											
										</h4>
										{isAdmin && (
												<>
													{product?.packageName &&
														(	
														<p className="text-xs text-gray-700">
														{product?.packageName}
														</p>
														)
													}
													{product?.packageVersion && <p className="text-xs text-gray-700">
														Version: {product?.packageVersion}
													</p>}
												</>
											)}
									</div>
								</div>
							</Link>
						))}
					</div>
				</div>
			)}
		</div>
	);
}
