// import { addFilter, applyFilters } from '@wordpress/hooks';
import appmaker from '@appmaker/core/index';
import InitialCustomize from './routes/InitialCustomize';
import AppDesign from './routes/AppDesign';
import { ColorSwatchIcon } from '@heroicons/react/solid';
import SubMenuItem from './components/SubMenuItem';
import AppDownload from './components/AppDownload';
import DownloadApps from './routes/DownloadApps';
import steps from './onboardingsteps'
const Plugin = {
	id: 'mobile-app',
	name: 'Mobile App',
	activate: () => {
		appmaker.addFilter('block-editor-header-menu', 'shopify-templates', (item) => <>{item} <AppDownload /></>)
		// steps.map(step => appmaker.doAction('register-onboarding-steps', step))

		appmaker.doAction('register-onboarding-steps', {
			title: 'Configure Social Login',
			id: 'download-app',
			route: '/app-design/misc',
			// onClick: () => setIsOpen(true),
			order: 10
		})
		appmaker.registerRoute({
			routeName: '/app-design',
			component: AppDesign,
		});
		appmaker.registerRoute({
			routeName: '/download-apps',
			component: DownloadApps,
		});

		/**
		 * Menu Items
		 */
		// appmaker.registerSideBarMenu({
		//     // name: 'Customize App',
		//     // routeName: `/initial-customize`,
		//     // icon: ColorSwatchIcon,
		//     // MenuItemComponent: SubMenuItem,
		// });
		appmaker.addFilter(
			'onboarding-menu-item',
			'mobile-app-onboarding',
			(menuItems = []) => {
				menuItems.push({
					name: 'Customize App',
					routeName: `/initial-customize`,
					icon: ColorSwatchIcon,
					MenuItemComponent: SubMenuItem,
				});
				return menuItems;
			}
		);
		appmaker.addFilter(
			'onboarding-routes',
			'mobile-app-onboarding',
			(routes = []) => {
				routes.push({
					routeName: '/initial-customize',
					component: InitialCustomize,
				});
				return routes;
			}
		);
		appmaker.registerSideBarMenu({
			name: 'Update App',
			routeName: `/app-design`,
			icon: ColorSwatchIcon,
			MenuItemComponent: SubMenuItem,
		});
		// appmaker.registerSideBarMenu({
		// 	name: 'v2Design App',
		// 	routeName: `/v2Design`,
		// 	icon: ColorSwatchIcon,
		// 	MenuItemComponent: SubMenuItem,
		// });
		// appmaker.registerSideBarMenu({
		// 	name: 'Download Apps',
		// 	routeName: `/download-apps`,
		// 	icon: FolderDownloadIcon,
		// });
	},
};

export default Plugin;
