import { useState } from 'react';
import LinkedRangeControl from './components/LinkedRangeControl';
import ColorPicker from '../../components/ColorPicker';
import { RangeControl } from '@wordpress/components';
import Select from './components/Select';
import DisplayContainer from './components/DisplayContainer';

const ImageStyles = ({ properties = {}, onChange, defaultValue = {} }) => {
	const { className, label } = properties;

	const [borderAttributes, setBorderAttributes] = useState(
		defaultValue.border || {
			borderTop: 0,
			borderRight: 0,
			borderBottom: 0,
			borderLeft: 0,
		}
	);
	const [borderRadiusAttributes, setBorderRadiusAttributes] = useState(
		defaultValue.borderRadius || {
			borderTopLeftRadius: 0,
			borderTopRightRadius: 0,
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
		}
	);

	const setStyles = (newAttributes) => {
		onChange && onChange({ ...defaultValue, ...newAttributes });
	};

	// Handlers for each style type
	const handleBorderChange = (newAttributes) => {
		setBorderAttributes(newAttributes);
		setStyles(newAttributes);
	};
	const handleBorderRadiusChange = (newAttributes) => {
		setBorderRadiusAttributes(newAttributes);
		setStyles(newAttributes);
	};

	return (
		<DisplayContainer className={className} title={label}>
			<>
				<RangeControl
					label="Width"
					name="width"
					value={defaultValue.width}
					onChange={(width) => setStyles({ width })}
					min={1}
					max={800}
				/>
				<RangeControl
					label="Height"
					name="height"
					value={defaultValue.height}
					onChange={(height) => setStyles({ height })}
					min={1}
					max={1920}
				/>
				<RangeControl
					label="Border Width"
					name="border-width"
					value={borderAttributes.borderWidth}
					onChange={(borderWidth) => handleBorderChange({ borderWidth })}
					min={0}
					max={10}
				/>
				<ColorPicker
					attributes={{
						label: 'Border Color',
						defaultColor: defaultValue?.borderColor,
						singleItem: true,
						onChangeComplete: (color) => {
							setStyles({ borderColor: color.hex });
						},
					}}
				/>
				<LinkedRangeControl
					defaultValue={defaultValue}
					attributes={borderRadiusAttributes}
					setAttributes={handleBorderRadiusChange}
					config={{
						label: 'Border Radius',
						property: 'borderRadius',
						sides: [
							'borderTopLeftRadius',
							'borderTopRightRadius',
							'borderBottomRightRadius',
							'borderBottomLeftRadius',
						],
						labels: ['Top Left', 'Top Right', 'Bottom Right', 'Bottom Left'],
						min: 0,
						max: 100,
					}}
				/>
				{/* overflow */}
				<Select
					label="Overflow"
					id="overflow"
					defaultValue={defaultValue?.overflow}
					onChange={(e) => setStyles({ overflow: e.target.value })}
					options={[
						{ value: 'visible', label: 'Visible' },
						{ value: 'hidden', label: 'Hidden' },
					]}
				/>
				{/* resizeMode */}
				<Select
					label="Resize Mode"
					id="resize-mode"
					defaultValue={defaultValue?.resizeMode}
					onChange={(e) => setStyles({ resizeMode: e.target.value })}
					options={[
						{ value: 'cover', label: 'Cover' },
						{ value: 'contain', label: 'Contain' },
						{ value: 'stretch', label: 'Stretch' },
						{ value: 'repeat', label: 'Repeat' },
						{ value: 'center', label: 'Center' },
					]}
				/>
				{/* objectFit */}
				<Select
					label="Object Fit"
					id="object-fit"
					defaultValue={defaultValue?.objectFit}
					onChange={(e) => setStyles({ objectFit: e.target.value })}
					options={[
						{ value: 'fill', label: 'Fill' },
						{ value: 'contain', label: 'Contain' },
						{ value: 'cover', label: 'Cover' },
						{ value: 'scale-down', label: 'Scale Down' },
					]}
				/>
				{/* opacity */}
				<RangeControl
					label="Opacity"
					name="opacity"
					value={defaultValue.opacity || 100}
					defaultValue={100}
					onChange={(opacity) => setStyles({ opacity })}
					min={0}
					max={100}
				/>
			</>
		</DisplayContainer>
	);
};

export default ImageStyles;
