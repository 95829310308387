import React, { useEffect, useState } from 'react';
import { isAdmin } from '@appmaker/core/store/user';
import { useQueryParam, StringParam } from 'use-query-params';
import { useQuery } from '@tanstack/react-query';
import { getAppBuild } from '../api/app-build';
import { getProjectId } from '@appmaker/core/store/project';
import Loader from '@appmaker/components/Loading';
import moment from 'moment';

const AppBuildConfig = () => {
	const [buildId] = useQueryParam('build_id', StringParam);
	const [isLoading, setIsLoading] = useState(false);
	const [buildData, setBuildData] = useState(null);
	const [isCopied, setIsCopied] = useState(false);
	const admin = isAdmin();
	const projectId = getProjectId();
	const handleCopyClick = () => {
		if (navigator.clipboard && buildData?.config) {
			navigator.clipboard.writeText(JSON.stringify(buildData?.config, null, 2));
			setIsCopied(true);
		}
	};
	const [searchQuery, setSearchQuery] = useState('');
	// const query = useQuery({
	// 	queryKey: ['build-with-id', 'admin-tools', buildId],
	// 	queryFn: () => ,
	// 	staleTime: Infinity,
	// 	cacheTime: 0,
	// });
	// useEffect(() => {
	// 	if (query?.data?.data?.data?.appBuild) {
	// 		console.log(query?.data?.data?.data?.appBuild);
	// 		setBuildData(query?.data?.data?.data?.appBuild);
	// 	}
	// }, [query?.data?.data]);
	useEffect(() => {
		(async () => {
			setIsLoading(true);
			try {
				const response = await getAppBuild(projectId, buildId);
				if (response?.data?.data?.appBuild) {
				
					setBuildData(response?.data?.data?.appBuild);
				}
			} catch (error) {
			} finally {
				setIsLoading(false);
			}
		})();
	}, [buildId, projectId]);
	useEffect(() => {
		if (isCopied) {
			setTimeout(() => {
				setIsCopied(false);
			}, 5000);
		}
	}, [isCopied]);
	if (!admin || !buildId) return null;

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};

	const highlightMatches = (text) => {
		const regex = new RegExp(searchQuery, 'gi');
		return text.replace(
			regex,
			(match) => `<span class="bg-yellow-200">${match}</span>`
		);
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<div className="w-full mx-auto mt-8 p-4 border rounded-lg overflow-hidden">
			<div className="mb-4">
				<h2 className="text-lg font-bold mb-2">{buildData?.name}</h2>
				<p className="text-sm text-gray-500">
					Created: {moment(buildData?.createdAt).format('LLL')} {'( '}
					{moment(buildData?.createdAt).fromNow()} {')'}
				</p>
				<p className="text-sm text-gray-500">
					Updated: {moment(buildData?.updatedAt).format('LLL')} {'( '}
					{moment(buildData?.updatedAt).fromNow()}
				</p>

				{/* Build duration from createdAt and updatedAt in minute*/}
				<p className="text-sm text-gray-500">
					Build Duration:{' '}
					{moment(buildData?.updatedAt).diff(buildData?.createdAt, 'minutes')}{' '}
					minutes
				</p>

				<p className="text-sm text-gray-500">platform: {buildData?.platform}</p>
				<p className="text-sm text-gray-500">
					Status: {buildData?.buildStatus}
				</p>
				{/* version,triggerType,templateVersion?.name, templateVersion?.version */}
				<p className="text-sm text-gray-500">version: {buildData?.version}</p>
				<p className="text-sm text-gray-500">
					Trigger Type: {buildData?.triggerType}
				</p>
				<p className="text-sm text-gray-500">
					Template: {buildData?.templateVersion?.name} (
					{buildData?.templateVersion?.version})
				</p>
				<p className="text-sm text-gray-500">
					Build Type: {buildData?.buildType}
				</p>
			</div>
			<div className="relative flex justify-end">
				<div className="mb-4 mr-4">
					<button
						className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
						onClick={handleCopyClick}
					>
						{isCopied ? 'Copied!' : 'Copy'}
					</button>
				</div>
				<div>
					<input
						type="text"
						placeholder="Search..."
						className="w-64 sm:w-80 border rounded p-2 text-sm"
						value={searchQuery}
						onChange={handleSearchChange}
					/>
				</div>
			</div>
			<div className="overflow-auto max-h-[600px] h-96">
				{' '}
				{/* Adjust the maximum height here */}
				{buildData?.config && (
					<pre
						className="bg-gray-100 p-4 rounded-lg"
						dangerouslySetInnerHTML={{
							__html: highlightMatches(
								JSON.stringify(buildData?.config, null, 2)
							),
						}}
					></pre>
				)}
			</div>
		</div>
	);
};

export default AppBuildConfig;
