import { useEffect, useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useProject } from '@appmaker/core/store/project';
import { useQueryParam, StringParam } from 'use-query-params';
import DownloadAppCards from './DownloadCard';
import graphQlApi from '@appmaker/core/api/graphql';
import { getProjectData } from '@appmaker/core/store/project';
import AppDownloadCard from 'packages/dashboard/components/AppDownloadCard';

const DownloadAppCardsModal = ({ isOpen, setIsOpen }) => {
	const [showAppDownload] = useQueryParam('showAppDownload', StringParam);
	const [apps, setApps] = useState([]);
	const [isLoading, setLoading] = useState(false);

	let buildCheckIntervalId = false;
	const { loading, projectId, projectData } = getProjectData();

	const getBuildStatus = async () => {
		const apps = await graphQlApi.appBuildStatus();
		setApps(apps);
		if (
			apps &&
			apps.filter((app) => app.buildInProgress === true).length === 0
		) {
			clearInterval(buildCheckIntervalId);
		}
	};
	const startBuildStatusCheck = async () => {
		setLoading(true);
		await getBuildStatus();
		setLoading(false);
		buildCheckIntervalId = setInterval(() => {
			getBuildStatus();
		}, 5000);

		// clear interval on re-render to avoid memory leaks
		return () => clearInterval(buildCheckIntervalId);
	};
	useEffect(() => {
		return startBuildStatusCheck();
	}, [projectId, isOpen]);

	const closeModal = () => {
		setIsOpen(false);
		if (buildCheckIntervalId !== false) {
			clearInterval(buildCheckIntervalId);
		}
	};
	useEffect(() => {
		if (showAppDownload) {
			setIsOpen(true);
		} else {
			setIsOpen(false);
		}
	}, [showAppDownload]);

	return (
		<>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					onClose={closeModal}
				>
					<Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

					<div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="inline-block h-screen align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-block w-full max-w-4xl my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
								<Dialog.Title
									as="h3"
									className="text-xl font-bold leading-6 text-gray-800 px-6 pt-6"
								>
									Download Apps
								</Dialog.Title>
								<div className="px-6 pb-6">
									<DownloadAppCards apps={apps} isLoading={isLoading} />
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default DownloadAppCardsModal;
