/* This example requires Tailwind CSS v2.0+ */
import React, { useEffect, Suspense, useState, lazy } from 'react';

import appmaker from '@appmaker/core/index';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import useProject from '@appmaker/core/hooks/useProject';
import useProjectData from '@appmaker/core/hooks/useProjectData';
import {
	setProject,
	setProjectId,
	usePlugins,
} from '@appmaker/core/store/project';
import { Helmet } from 'react-helmet';
import SidebarNavigation, { SideNavFooter } from 'components/SidebarNavigation';
import DashboardLoading from 'components/skeltonLoading/Dashboard';
import DashboardLayout from 'components/layout/dashboard';
import {
	setSideBarStatus,
	setSmallSidebar,
} from '@appmaker/core/store/settings';
import isEmpty from 'lodash/isEmpty';
import Banner from '@appmaker/components/Banner';
import useCustomBlocks from 'packages/e-commerce-blocks/hooks/custom-blocks';
import { registerPartnerCustomBlocks } from 'packages/e-commerce-blocks/register-partner-blocks';
import { setUser } from '@appmaker/core/store/user';
import RedirectConsole from './RedirectToConsole';
const getAllNavs = () => {
	const menuItems = appmaker.allMenuItems();
	return menuItems;
};
const SideNav = () => {
	const navigation = getAllNavs();
	return <SidebarNavigation navigation={navigation} />;
};
const RouterHelper = ({ Component, setFullLayout, AdditionalComponent }) => {
	useEffect(() => {
		// if (Component.fullLayout !== fullLayout)
		if (Component.fullLayout) setFullLayout(true);
		else setFullLayout(false);
	}, [Component.fullLayout, setFullLayout]);
	useEffect(() => {
		// if (Component.fullLayout !== fullLayout)
		if (Component.hideSideBar === true) setSideBarStatus(false);
		else setSideBarStatus(true);
	}, [Component.hideSideBar]);
	useEffect(() => {
		// if (Component.fullLayout !== fullLayout)
		if (Component.smallSidebar === true) setSmallSidebar(true);
		else setSmallSidebar(false);
	}, [Component.smallSidebar]);

	return (
		<>
			{AdditionalComponent ? AdditionalComponent : <Component />}

		</>
	);
};
function HeaderFilter() {
	const HeaderExtraMenu = appmaker.applyFilters(
		'dashboard-header-menu',
		() => null
	);
	return HeaderExtraMenu;
}

export default function Dashboard() {
	const [fullLayout, setFullLayout] = useState(false);
	const [pluginsLoaded, setPLuginsLoaded] = useState(false);
	let { path } = useRouteMatch();
	const { projectId } = useProject();
	const { projectData, loading } = useProjectData({ projectId });
	const { blocks: customBlocks , loading: isLoadingCustomBlocks } = useCustomBlocks({ projectId });
	const plugins = usePlugins();
	const AdditionalComponent = appmaker.applyFilters(
		'additional-override-component-full-screen',
		null
	);
	useEffect(() => {
		if (
			(!isEmpty(projectData) && projectData?.status !== false) &&
			plugins &&
			plugins.length > 0 &&
			!pluginsLoaded
		) {
			let allPlugins = plugins;
			if (projectData?.feature?.dashboard_sidebar_plugins) {
				allPlugins = allPlugins.concat(
					projectData?.feature?.dashboard_sidebar_plugins
				);
			}
			let uniquePlugins = [];
			allPlugins?.forEach((plug) => {
				const unq = uniquePlugins?.find((item) => item.id === plug.id);
				if (!unq) {
					uniquePlugins?.push(plug);
				}
			});
			appmaker.activatePlugins(uniquePlugins);
			setPLuginsLoaded(true);
		} else if ((isEmpty(projectData) || projectData?.status === false) && !loading) {
			setUser({
				logged_in: false
			})
		}
	}, [plugins]);

	useEffect(() => {
		if (projectData) setProject(projectData);
	}, [projectData]);
	useEffect(() => {
		setProjectId(projectId);
	}, [projectId]);
	useEffect(() => {
		if (customBlocks && customBlocks?.length > 0) {
			registerPartnerCustomBlocks(customBlocks);
		}
	}, [customBlocks]);
	// return null
	const HeaderExtraContent = appmaker.applyFilters(
		'dashboard-header-content',
		() => null
	);
	return (loading || isLoadingCustomBlocks || !pluginsLoaded) ? (
		<DashboardLoading />
	) : (
		<>
			{HeaderFilter}
			<RedirectConsole />
			<DashboardLayout
				appIcon={projectData.icon}
				appName={projectData.name}
				templateName={projectData.template}
				sideNav={<SideNav />}
				// sideNavFooter={<SideNavFooter />}
				fullLayout={fullLayout}
			>
				{HeaderExtraContent}
				<Helmet>
					<title>{projectData.name} - Appmaker.xyz</title>
				</Helmet>
				<Suspense fallback={<DashboardLoading />}>
					<Switch>
						{appmaker.allRoutesArray().map((item) => {
							if (item.exact) {
								return (
									<Route
										exact
										key={item.routeName}
										path={`${path}${item.routeName}`}
									>
										<RouterHelper
											Component={item.component}
											setFullLayout={setFullLayout}
											AdditionalComponent={AdditionalComponent}
										/>
										{/* <item.component /> */}
									</Route>
								);
							}
							return (
								<Route key={item.routeName} path={`${path}${item.routeName}`}>
									<RouterHelper
										Component={item.component}
										setFullLayout={setFullLayout}
										AdditionalComponent={AdditionalComponent}
									/>
									{/* <item.component /> */}
								</Route>
							);
						})}
					</Switch>
				</Suspense>
			</DashboardLayout>
		</>
	);
}
