import { isAdmin } from '@appmaker/core/store/user';
import useRouterPath from '@appmaker/core/hooks/useRouterPath';
import { Link } from '@appmaker/core/routes';
import {
	PencilIcon,
	TableIcon,
	LockClosedIcon
} from '@heroicons/react/solid';
import classNames from 'classnames';

const AdminEditExtension = ({ id }) => {
	const getRouterPath = useRouterPath();
	// const [show, setShow] = useState(false);
	const isAdminUser = isAdmin();

	if (!isAdminUser || !id) {
		return null;
	}

	return (
		<div
			className={classNames(
				'relative inline-flex items-center p-4 bg-red-100 rounded-md',
				{
					'border border-red-600': isAdminUser,
				}
			)}
		>
			<div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
				<LockClosedIcon className="h-4 w-4" aria-hidden="true" />
			</div>
			{/* <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -left-2 dark:border-gray-900">
				{show ? (
					<EyeIcon
						className="h-4 w-4"
						aria-hidden="true"
						onClick={() => setShow(false)}
					/>
				) : (
					<EyeOffIcon
						className="h-4 w-4"
						aria-hidden="true"
						onClick={() => setShow(true)}
					/>
				)}
			</div> */}
			<div className="flex space-x-1">
					{/* <Link
						to={getRouterPath(`/admin-tools/extension-update/${id}`)}
						className="inline-flex justify-self-end bg-red-600 border border-transparent rounded-md py-1 px-3 items-center justify-center text-base font-normal text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
					>
						<PencilIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
						Edit Extension
					</Link> */}
					<Link
						to={getRouterPath(`/admin-tools/extension-project-list/${id}`)}
						className="inline-flex justify-self-end bg-red-600 border border-transparent rounded-md py-1 px-3 items-center justify-center text-base font-normal text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
					>
						<TableIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
						Project List
					</Link>
				</div>

		</div>
	);
};

export default AdminEditExtension;
